'use client'

import { Hero } from '~/components/marketing/hero'
import { useMarketing } from '~/hooks/use-marketing'

const SectionHero = () => {
  const { toggleModal } = useMarketing()

  return (
    <Hero
      showBrand
      className="mb-8"
      data={{
        title: 'Crie páginas exclusivas para seus empreendimentos',
        description:
          'Crie páginas que cativam e convertem. O Construkto empodera construtoras a destacar cada projeto com páginas personalizadas, otimizadas para performance e visibilidade máxima. Transforme a maneira como você apresenta seus empreendimentos!',
        image: '/images/hero-orange.png',
        action: {
          label: 'Quero ser avisado no lançamento',
          onClick: () => toggleModal(true),
        },
      }}
    />
  )
}

export { SectionHero }
