import { heroVariants, type HeroVariantProps } from './hero.variants'

export type UseHeroProps = HeroVariantProps & {
  className?: string
  showBrand?: boolean
  data: {
    title: string
    description: string
    image: string
    action?: {
      label: string
      url?: string
      onClick?: () => void
    }
  }
}

const useHero = (props: UseHeroProps) => {
  const { className, showBrand = false, data } = props
  const styles = heroVariants()

  return {
    styles,
    className,
    showBrand,
    data,
  }
}

export { useHero }
