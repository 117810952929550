import { tv, type VariantProps } from '../../lib/utils'

const avatarGroupVariants = tv({
  slots: {
    base: ['flex -space-x-4'],
    avatar: ['border-2'],
  },
  variants: {
    borderColor: {
      light: {
        avatar: ['border-white'],
      },
      dark: {
        avatar: ['border-gray-600'],
      },
      primary: {
        avatar: ['border-primary-600'],
      },
      secondary: {
        avatar: ['border-secondary-600'],
      },
      orange: {
        avatar: ['border-orange-600'],
      },
    },
  },
  defaultVariants: {
    borderColor: 'orange',
  },
})

export type AvatarGroupVariantProps = VariantProps<typeof avatarGroupVariants>

export { avatarGroupVariants }
