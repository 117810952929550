import { tv, type VariantProps } from '../../lib/utils'

const bannerVariants = tv({
  slots: {
    base: [
      'relative',
      'bg-gray-700 text-white',
      'overflow-hidden',
      'rounded-2xl',
      'p-8 md:p-14',
      'before:w-full before:h-full',
      'before:absolute before:z-10 before:inset-0',
      'before:bg-gradient-to-b before:from-transparent before:to-gray-700',
    ],
    texture: ['absolute inset-0', 'h-full lg:w-full lg:h-auto'],
    content: [
      'relative z-20',
      'flex flex-col lg:flex-row items-center gap-6 lg:gap-8',
    ],
    emoji: ['mt-0.5'],
    title: ['md:max-w-md', 'text-center lg:text-left'],
    brand: ['h-[35px] w-[15px]', 'text-white'],
    action: ['lg:ml-auto'],
  },
})

export type BannerVariantProps = VariantProps<typeof bannerVariants>

export { bannerVariants }
