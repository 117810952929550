import { bannerVariants } from './banner.variants'

export interface UseBannerProps {
  className?: string
  emoji?: string
  title: string
  action?: {
    label: string
    url?: string
    onClick?: () => void
  }
}

const useBanner = (props: UseBannerProps) => {
  const { className = '', ...rest } = props

  const styles = bannerVariants()

  return {
    styles,
    className,
    ...rest,
  }
}

export { useBanner }
