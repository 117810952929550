import { useCallback, useContext } from 'react'
import { MarketingContext } from '~/contexts/marketing'

export const useMarketing = () => {
  const { state, setState } = useContext(MarketingContext)

  const toggleModal = useCallback(
    (open: boolean) => {
      setState((prevState) => ({ ...prevState, showModal: open }))
    },
    [state.showModal]
  )

  return {
    ...state,
    toggleModal,
  }
}
