export interface BannerTextureProps {
  className?: string
}

const BannerTexture = ({ className = '' }: BannerTextureProps) => {
  return (
    <svg
      className={className}
      viewBox="0 0 1112 318"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fillOpacity={0.02}>
        <path
          d="M174.51 0H163.05L119.14 43.91L75.23 0H63.77L119.14 55.38L174.51 0Z"
          fill="white"
        />
        <path
          d="M373.08 0H361.62L357.42 4.19995L353.23 0H341.76L357.42 15.66L373.08 0Z"
          fill="white"
        />
        <path
          d="M1016.9 39.72L1032.56 24.05L1072.27 63.77L1111.98 24.0601V35.52L1072.27 75.24L1032.56 35.52L1028.36 39.72L1068.07 79.4301L1032.56 114.95L997.05 79.4301H997.04L957.33 39.72L997.04 0H1008.5L968.79 39.72L1032.56 103.48L1056.61 79.4301L1016.9 39.72Z"
          fill="white"
        />
        <path
          d="M1032.56 4.19995L1072.27 43.91L1111.98 4.19995V15.66L1072.27 55.38L1032.56 15.66L1008.5 39.72L1048.21 79.4301L1032.56 95.09L977.18 39.72L1016.9 0H1028.36L988.65 39.72L1028.35 79.4301H1028.36L1032.56 83.62L1036.75 79.4301L997.04 39.72L1032.56 4.19995Z"
          fill="white"
        />
        <path
          d="M1096.32 0H1107.79L1072.27 35.52L1036.75 0H1048.22L1072.27 24.0601L1096.32 0Z"
          fill="white"
        />
        <path
          d="M1087.93 0H1076.47L1072.27 4.19995L1068.07 0H1056.61L1072.27 15.66L1087.93 0Z"
          fill="white"
        />
        <path
          d="M595.7 134.8L540.34 79.4301L580.05 39.72L540.33 0H551.79L591.51 39.72L551.8 79.4301L595.7 123.34L639.61 79.4301L635.42 75.24L631.22 79.4301L595.7 114.95L560.19 79.4301L599.9 39.72L560.19 0H571.65L611.36 39.72L571.65 79.4301L595.7 103.48L635.42 63.77L651.07 79.4301L595.7 134.8Z"
          fill="white"
        />
        <path
          d="M869.5 0H858.04L833.99 24.0601L809.93 0H798.47L833.99 35.52L869.5 0Z"
          fill="white"
        />
        <path
          d="M719.04 0H730.51L675.13 55.38L619.76 0H631.22L675.13 43.91L719.04 0Z"
          fill="white"
        />
        <path
          d="M690.79 0H679.33L675.13 4.19995L670.94 0H659.47L675.13 15.66L690.79 0Z"
          fill="white"
        />
        <path
          d="M738.9 0H750.36L675.13 75.24L599.9 0H611.37L675.13 63.77L738.9 0Z"
          fill="white"
        />
        <path
          d="M710.65 0H699.19L675.13 24.0601L651.08 0H639.62L675.13 35.52L710.65 0Z"
          fill="white"
        />
        <path
          d="M838.19 0H849.65L833.99 15.66L818.33 0H829.79L833.99 4.19995L838.19 0Z"
          fill="white"
        />
        <path
          d="M1072.27 163.06L1076.47 158.86L1036.75 119.15L1111.98 43.91V55.38L1087.93 79.4301H1087.92L1048.22 119.15L1087.93 158.86L1072.27 174.52L992.84 95.09L929.08 158.86H929.07L889.36 198.58L929.07 238.29L913.41 253.95L897.76 238.29H897.75L873.7 214.24L849.65 238.29L913.41 302.06L977.18 238.29L937.47 198.58L992.84 143.2L1008.5 158.86L968.79 198.58L1032.56 262.35L1056.61 238.29L1016.9 198.58L1032.56 182.92L1072.27 222.64L1111.98 182.92V194.38L1072.27 234.1L1032.56 194.38L1028.36 198.58L1068.07 238.29L1032.56 273.81L997.05 238.29H997.04L957.33 198.58L997.04 158.86L992.84 154.66L988.65 158.86L948.93 198.58L988.64 238.29L913.41 313.53L838.19 238.29L873.7 202.78L909.21 238.29H909.22L913.41 242.49L917.61 238.29L877.9 198.58L992.84 83.62L1072.27 163.06Z"
          fill="white"
        />
        <path
          d="M1096.32 79.4301L1056.61 119.15L1096.32 158.86L1072.27 182.92L992.84 103.48L897.75 198.58L937.47 238.29L913.41 262.35L873.7 222.64L858.05 238.29L913.41 293.67L968.78 238.29L929.07 198.58L992.84 134.8L1016.9 158.86L977.18 198.58L1032.56 253.95L1048.21 238.29L1008.5 198.58L1032.56 174.52L1072.27 214.24L1111.98 174.52V163.06L1072.27 202.78L1032.56 163.06L997.04 198.58L1036.75 238.29L1032.56 242.49L1028.36 238.29H1028.35L988.65 198.58L1028.36 158.86L992.84 123.34L957.33 158.86H957.32L917.61 198.58L957.32 238.29L913.41 282.2L869.51 238.29L873.7 234.1L877.9 238.29L913.41 273.81L948.93 238.29L909.22 198.58L992.84 114.95L1072.27 194.38L1107.79 158.86L1068.07 119.15L1107.78 79.4301H1107.79L1111.98 75.24V63.77L1096.33 79.4301H1096.32Z"
          fill="white"
        />
        <path
          d="M913.41 103.48L937.47 79.4301L897.75 39.72L937.47 0H948.93L909.22 39.72L948.93 79.4301L913.41 114.95L877.9 79.4301L873.7 75.24L869.51 79.4301L913.41 123.34L957.32 79.4301L917.61 39.72L957.32 0H968.79L929.07 39.72L968.78 79.4301L913.41 134.8L858.05 79.4301L873.7 63.77L913.41 103.48Z"
          fill="white"
        />
        <path
          d="M392.94 0H381.48L357.42 24.0601L333.37 0H321.91L357.42 35.52L392.94 0Z"
          fill="white"
        />
        <path
          d="M361.62 119.15L397.14 83.63V83.62L401.33 79.4301L397.14 75.24L392.94 79.4301H392.93L353.23 119.15L392.94 158.86L357.42 194.38L278 114.95L194.37 198.58L234.08 238.29L198.57 273.81L163.06 238.29H163.05L158.86 234.1L154.66 238.29L198.57 282.2L242.48 238.29L202.77 198.58L278 123.34L313.51 158.86L273.8 198.58L317.71 242.49L321.9 238.29L282.19 198.58L317.71 163.06L357.42 202.78L397.14 163.06L436.85 202.78L476.56 163.06L512.08 198.58L472.37 238.29H472.36L432.65 278.01L472.37 317.72H460.9L421.19 278.01L460.9 238.29H460.91L500.62 198.58L476.56 174.52L436.85 214.24L397.14 174.52L357.42 214.24L317.71 174.52L293.65 198.58L333.36 238.29L317.71 253.95L262.34 198.58L302.05 158.86L278 134.8L214.23 198.58L253.94 238.29L198.57 293.67L143.2 238.29L158.86 222.64L174.51 238.29H174.52L198.57 262.35L222.62 238.29L182.91 198.58L278 103.48L357.42 182.92L381.48 158.86L341.76 119.15L397.14 63.77L412.79 79.4301L373.08 119.15L436.85 182.92L460.9 158.86L421.19 119.15L460.9 79.4301H460.91L500.62 39.72L476.56 15.66L436.85 55.38L397.14 15.66L357.42 55.38L317.71 15.66L293.65 39.72L333.36 79.4301L317.71 95.09L262.34 39.72L302.05 0H313.51L273.8 39.72L313.51 79.4301L317.71 83.62L321.9 79.4301L282.19 39.72L317.71 4.19995L357.42 43.91L397.14 4.19995L436.85 43.91L476.56 4.19995L512.08 39.72L472.37 79.4301H472.36L432.65 119.15L472.37 158.86L436.85 194.38L361.62 119.15Z"
          fill="white"
        />
        <path
          d="M520.48 79.4301L595.7 154.66L670.93 79.4301L635.42 43.91L599.91 79.4301H599.9L595.7 83.62L591.51 79.4301L631.22 39.72L591.51 0H580.05L619.76 39.72L580.05 79.4301L595.7 95.09L635.42 55.38L659.47 79.4301L595.7 143.2L531.94 79.4301L571.65 39.72L531.94 0H520.48L560.19 39.72L520.48 79.4301Z"
          fill="white"
        />
        <path
          d="M143.19 0H154.66L119.14 35.52L83.6299 0H95.0898L119.14 24.0601L143.19 0Z"
          fill="white"
        />
        <path
          d="M222.62 79.4301L198.57 103.48L174.52 79.4301H174.51L158.86 63.77L143.2 79.4301L198.57 134.8L253.94 79.4301L214.23 39.72L253.94 0H242.48L202.77 39.72L242.48 79.4301L198.57 123.34L154.66 79.4301L158.86 75.24L163.05 79.4301H163.06L198.57 114.95L234.08 79.4301L194.37 39.72L234.09 0H222.62L182.91 39.72L222.62 79.4301Z"
          fill="white"
        />
        <path
          d="M198.57 83.62L202.76 79.4301L163.05 39.72L202.77 0H214.23L174.52 39.72L214.22 79.4301L198.57 95.09L182.92 79.4301H182.91L158.86 55.38L134.81 79.4301L198.57 143.2L262.33 79.4301L222.62 39.72L262.34 0H273.8L234.09 39.72L273.79 79.4301L198.57 154.66L123.35 79.4301L158.86 43.91L194.37 79.4301L198.57 83.62Z"
          fill="white"
        />
        <path
          d="M452.51 0H441.05L436.85 4.19995L432.66 0H421.19L436.85 15.66L452.51 0Z"
          fill="white"
        />
        <path
          d="M460.9 0H472.37L436.85 35.52L401.33 0H412.8L436.85 24.0601L460.9 0Z"
          fill="white"
        />
        <path
          d="M913.41 83.62L917.61 79.4301L877.9 39.72L917.61 0H929.07L889.36 39.72L929.07 79.4301L913.41 95.09L897.76 79.4301H897.75L873.7 55.38L849.65 79.4301L913.41 143.2L977.18 79.4301L937.47 39.72L977.18 0H988.65L948.93 39.72L988.64 79.4301L913.41 154.66L838.19 79.4301L873.7 43.91L909.21 79.4301H909.22L913.41 83.62Z"
          fill="white"
        />
        <path
          d="M1111.98 154.66V143.2L1087.93 119.15L1111.98 95.09V83.62L1076.47 119.15L1111.98 154.66Z"
          fill="white"
        />
        <path
          d="M1111.98 123.34V134.8L1096.32 119.15L1111.98 103.48V114.95L1107.78 119.15L1111.98 123.34Z"
          fill="white"
        />
        <path
          d="M0 4.19995V15.66L24.0601 39.72L0 63.77V75.24L35.52 39.72L0 4.19995Z"
          fill="white"
        />
        <path
          d="M55.3701 39.72L0 95.09V83.62L4.2002 79.4301L43.9102 39.72L4.2002 0H15.6602L55.3701 39.72Z"
          fill="white"
        />
        <path
          d="M35.52 79.4301L75.23 39.72L35.52 0H24.0601L63.77 39.72L0 103.48V114.95L35.5098 79.4301H35.52Z"
          fill="white"
        />
        <path
          d="M0 35.52V24.0601L15.6602 39.72L0 55.38V43.91L4.2002 39.72L0 35.52Z"
          fill="white"
        />
        <path
          d="M194.37 0H182.91L119.14 63.77L55.3701 0H43.9102L119.14 75.24L194.37 0Z"
          fill="white"
        />
        <path
          d="M123.34 0H134.8L119.14 15.66L103.48 0H114.95L119.14 4.19995L123.34 0Z"
          fill="white"
        />
        <path
          d="M738.9 317.72H750.36L754.56 313.53L758.76 317.72H770.22L754.56 302.06L738.9 317.72Z"
          fill="white"
        />
        <path
          d="M611.37 317.72H599.9L635.42 282.2L670.94 317.72H659.47L635.42 293.67L611.37 317.72Z"
          fill="white"
        />
        <path
          d="M992.84 242.49L917.61 317.72H929.08L992.84 253.95L1056.61 317.72H1068.07L992.84 242.49Z"
          fill="white"
        />
        <path
          d="M79.4302 202.78L154.66 278.01L114.94 317.72H103.48L143.19 278.01L103.49 238.29H103.48L79.4302 214.24L55.3799 238.29L95.0898 278.01L79.4302 293.67L39.71 253.95L0 293.67V282.2L39.71 242.49L79.4302 282.2L83.6201 278.01L43.9199 238.29L79.4302 202.78Z"
          fill="white"
        />
        <path
          d="M858.04 317.72H869.5L873.7 313.53L877.9 317.72H889.36L873.7 302.06L858.04 317.72Z"
          fill="white"
        />
        <path
          d="M234.09 317.72H222.62L278 262.35L333.37 317.72H321.91L278 273.81L234.09 317.72Z"
          fill="white"
        />
        <path
          d="M480.76 317.72H492.23L516.28 293.67L540.33 317.72H551.79L516.28 282.2L480.76 317.72Z"
          fill="white"
        />
        <path
          d="M631.22 317.72H619.76L635.42 302.06L651.08 317.72H639.62L635.42 313.53L631.22 317.72Z"
          fill="white"
        />
        <path
          d="M242.48 317.72H253.94L278 293.67L302.05 317.72H313.51L278 282.2L242.48 317.72Z"
          fill="white"
        />
        <path
          d="M273.8 317.72H262.34L278 302.06L293.65 317.72H282.19L278 313.53L273.8 317.72Z"
          fill="white"
        />
        <path
          d="M790.08 238.29H790.07L750.36 198.58L790.08 158.86L754.56 123.34L675.13 202.78L631.22 158.86L635.42 154.66L675.13 194.38L710.65 158.86L670.94 119.15L710.64 79.4301H710.65L714.85 75.24L719.04 79.4301L679.33 119.15L714.85 154.66L754.56 114.95L794.28 154.66L829.79 119.15L794.28 83.62L790.08 79.4301H790.07L750.36 39.72L790.08 0H778.62L738.9 39.72L818.33 119.15L794.28 143.2L754.56 103.48L714.85 143.2L690.79 119.15L730.5 79.4301L714.85 63.77L659.47 119.15L699.19 158.86L675.13 182.92L635.42 143.2L619.76 158.86L675.13 214.24L754.56 134.8L778.61 158.86L738.9 198.58L818.33 278.01L794.28 302.06L754.56 262.35L714.85 302.06L690.79 278.01L730.5 238.29L714.85 222.64L659.47 278.01L699.19 317.72H710.65L670.94 278.01L710.64 238.29H710.65L714.85 234.1L719.04 238.29L679.33 278.01L714.85 313.53L754.56 273.81L794.28 313.53L829.79 278.01L790.08 238.29Z"
          fill="white"
        />
        <path
          d="M770.22 198.58L849.65 278.01L809.93 317.72H798.47L838.19 278.01L798.48 238.29H798.47L758.76 198.58L794.28 163.06L833.99 202.78L877.9 158.86L873.7 154.66L833.99 194.38L798.47 158.86L838.19 119.15L798.48 79.4301H798.47L758.76 39.72L794.28 4.19995L833.99 43.91L877.9 0H889.36L833.99 55.38L794.28 15.66L770.22 39.72L849.65 119.15L809.93 158.86L833.99 182.92L873.7 143.2L889.36 158.86L833.99 214.24L794.28 174.52L770.22 198.58Z"
          fill="white"
        />
        <path
          d="M714.85 214.24L738.9 238.29L699.19 278.01L714.85 293.67L754.56 253.95L794.28 293.67L809.93 278.01L770.22 238.29H770.21L730.51 198.58L770.22 158.86L754.56 143.2L675.13 222.64L611.37 158.86L635.42 134.8L675.13 174.52L690.79 158.86L651.08 119.15L714.85 55.38L738.9 79.4301L699.19 119.15L714.85 134.8L754.56 95.09L794.28 134.8L809.93 119.15L770.22 79.4301H770.21L730.51 39.72L770.22 0H758.76L719.04 39.72L794.28 114.95L798.47 119.15L794.28 123.34L754.56 83.62L714.85 123.34L710.65 119.15L750.36 79.4301L714.85 43.91L679.34 79.4301H679.33L639.62 119.15L679.33 158.86L675.13 163.06L670.94 158.86L635.42 123.34L599.9 158.86L675.13 234.1L754.56 154.66L758.76 158.86L719.04 198.58L794.28 273.81L798.47 278.01L794.28 282.2L754.56 242.49L714.85 282.21L710.65 278.01L750.36 238.29L714.85 202.78L679.34 238.29H679.33L639.62 278.01L679.33 317.72H690.79L651.08 278.01L714.85 214.24Z"
          fill="white"
        />
        <path
          d="M790.08 198.58L869.5 278.01L829.79 317.72H818.33L858.04 278.01L778.61 198.58L794.28 182.92L833.99 222.64L897.76 158.86L873.7 134.8L833.99 174.52L818.33 158.86L858.04 119.15L778.61 39.72L794.28 24.05L833.99 63.77L897.76 0H909.22L833.99 75.24L794.28 35.52L790.08 39.72L869.5 119.15L829.79 158.86L833.99 163.06L873.7 123.34L909.22 158.86L833.99 234.1L794.28 194.38L790.08 198.58Z"
          fill="white"
        />
        <path
          d="M1076.47 317.72H1087.93L1048.22 278.01L1087.92 238.29H1087.93L1111.98 214.24V202.78L1036.75 278.01L1076.47 317.72Z"
          fill="white"
        />
        <path
          d="M1056.61 278.01L1096.32 238.29H1096.33L1111.98 222.64V234.1L1107.79 238.29H1107.78L1068.07 278.01L1107.78 317.72H1096.32L1056.61 278.01Z"
          fill="white"
        />
        <path
          d="M361.62 278.01L397.14 242.49L401.33 238.29L397.14 234.1L392.94 238.29H392.93L353.23 278.01L392.94 317.72H381.48L341.76 278.01L397.14 222.64L412.79 238.29L373.08 278.01L412.8 317.72H401.33L361.62 278.01Z"
          fill="white"
        />
        <path
          d="M520.48 238.29L595.7 313.53L670.93 238.29L635.42 202.78L599.91 238.29H599.9L595.7 242.49L591.51 238.29L631.22 198.58L516.28 83.62L476.57 123.34L472.37 119.15L551.79 39.72L512.08 0H500.62L540.33 39.72L460.9 119.15L476.57 134.8L516.28 95.09L619.76 198.58L580.05 238.29L595.7 253.95L635.42 214.24L659.47 238.29L595.7 302.06L531.94 238.29L571.65 198.58L516.28 143.2L500.62 158.86L540.33 198.58L460.9 278.01L476.57 293.67L516.28 253.95L580.05 317.72H591.51L516.28 242.49L476.57 282.21L472.37 278.01L551.79 198.58L512.08 158.86L516.28 154.66L560.19 198.58L520.48 238.29Z"
          fill="white"
        />
        <path
          d="M452.51 278.01L476.57 302.06L516.28 262.35L571.65 317.72H560.19L516.28 273.81L476.57 313.53L441.05 278.01L480.76 238.29H480.77L520.48 198.58L480.76 158.86L516.28 123.34L591.51 198.58L551.8 238.29L595.7 282.2L639.61 238.29L635.42 234.1L631.22 238.29L595.7 273.81L560.19 238.29L599.9 198.58L516.28 114.95L476.57 154.66L441.05 119.15L480.76 79.4301H480.77L520.48 39.72L480.76 0H492.22L531.94 39.72L492.23 79.4301H492.22L452.51 119.15L476.57 143.2L516.28 103.48L611.36 198.58L571.65 238.29L595.7 262.35L635.42 222.64L651.07 238.29L595.7 293.67L540.34 238.29L580.05 198.58L516.28 134.8L492.23 158.86L531.94 198.58L492.23 238.29H492.22L452.51 278.01Z"
          fill="white"
        />
        <path
          d="M392.94 278.01L432.65 238.29L397.14 202.78L361.63 238.29H361.62L321.91 278.01L361.62 317.72H373.08L333.37 278.01L397.14 214.24L421.19 238.29L381.48 278.01L421.19 317.72H432.65L397.14 282.2L392.94 278.01Z"
          fill="white"
        />
        <path
          d="M452.5 238.29H452.51L492.22 198.58L476.56 182.92L436.85 222.64L397.14 182.92L357.42 222.64L317.71 182.92L302.05 198.58L341.76 238.29L317.71 262.35L253.94 198.58L293.66 158.86L278 143.2L222.62 198.58L262.33 238.29L198.57 302.06L134.81 238.29L158.86 214.24L182.91 238.29H182.92L198.57 253.95L214.22 238.29L174.52 198.58L278 95.09L357.42 174.52L373.08 158.86L333.37 119.15L397.14 55.38L421.19 79.4301L381.48 119.15L436.85 174.52L452.51 158.86L412.8 119.15L452.5 79.4301H452.51L492.22 39.72L476.56 24.0601L436.85 63.77L397.14 24.0601L357.42 63.77L317.71 24.05L302.05 39.72L341.76 79.4301L317.71 103.48L253.94 39.72L293.66 0H282.19L242.48 39.72L282.19 79.4301H282.2L317.71 114.95L353.22 79.4301L313.51 39.72L317.71 35.52L357.42 75.24L397.14 35.52L436.85 75.24L476.56 35.52L480.76 39.72L401.33 119.15L441.05 158.86L436.85 163.06L432.66 158.86H432.65L397.14 123.34L392.94 119.15L432.65 79.4301L397.14 43.91L361.63 79.4301H361.62L321.91 119.15L361.62 158.86L357.42 163.06L353.23 158.86L278 83.62L163.05 198.58L202.76 238.29L198.57 242.49L158.86 202.78L123.35 238.29L198.57 313.53L273.79 238.29L234.09 198.58L278 154.66L282.19 158.86L242.48 198.58L282.19 238.29H282.2L317.71 273.81L353.22 238.29L313.51 198.58L317.71 194.38L357.42 234.1L397.14 194.38L436.85 234.1L476.56 194.38L480.76 198.58L401.33 278.01L441.05 317.72H452.51L412.8 278.01L452.5 238.29Z"
          fill="white"
        />
        <path
          d="M1111.98 302.06V313.53L1076.47 278.01L1111.98 242.49V253.95L1087.93 278.01L1111.98 302.06Z"
          fill="white"
        />
        <path
          d="M1111.98 293.67V282.2L1107.78 278.01L1111.98 273.81V262.35L1096.32 278.01L1111.98 293.67Z"
          fill="white"
        />
        <path
          d="M0 174.52V163.06L35.52 198.58L0 234.1V222.64L24.0601 198.58L0 174.52Z"
          fill="white"
        />
        <path
          d="M55.3701 198.58L15.6602 158.86L39.71 134.8L119.14 214.24L174.51 158.86L158.86 143.2L119.14 182.92L95.0898 158.86L134.8 119.15L95.0898 79.4301H95.0801L79.4302 63.77L63.77 79.4301L103.48 119.15L79.4302 143.2L39.71 103.48L0 143.2V154.66L39.71 114.95L79.4302 154.66L114.94 119.15L75.23 79.4301L79.4302 75.24L83.6201 79.4301H83.6299L123.34 119.15L83.6299 158.86L119.14 194.38L158.86 154.66L163.05 158.86L119.14 202.78L39.71 123.34L4.2002 158.86L43.9102 198.58L0 242.49V253.95L55.3701 198.58Z"
          fill="white"
        />
        <path
          d="M75.23 198.58L35.52 238.29H35.5098L0 273.81V262.35L63.77 198.58L24.0601 158.86L39.71 143.2L119.14 222.64L182.91 158.86L158.86 134.8L119.14 174.52L103.48 158.86L143.19 119.15L103.49 79.4301H103.48L79.4302 55.38L55.3799 79.4301L95.0898 119.15L79.4302 134.8L39.71 95.09L0 134.8V123.34L39.71 83.62L79.4302 123.34L83.6201 119.15L43.9199 79.4301L79.4302 43.91L154.66 119.15L114.95 158.86L119.14 163.06L158.86 123.34L194.37 158.86L119.14 234.1L39.71 154.66L35.52 158.86L75.23 198.58Z"
          fill="white"
        />
        <path
          d="M0 182.92V194.38L4.2002 198.58L0 202.78V214.24L15.6602 198.58L0 182.92Z"
          fill="white"
        />
        <path
          d="M512.08 317.72H500.62L516.28 302.06L531.94 317.72H520.48L516.28 313.53L512.08 317.72Z"
          fill="white"
        />
        <path
          d="M719.04 317.72H730.51L754.56 293.67L778.61 317.72H790.08L754.56 282.2L719.04 317.72Z"
          fill="white"
        />
        <path
          d="M154.66 317.72H143.19L158.86 302.06L174.52 317.72H163.05L158.86 313.53L154.66 317.72Z"
          fill="white"
        />
        <path
          d="M95.0898 238.29H95.0801L79.4302 222.64L63.77 238.29L103.48 278.01L79.4302 302.06L39.71 262.35L0 302.06V313.53L39.71 273.81L79.4302 313.53L114.94 278.01L75.23 238.29L79.4302 234.1L83.6201 238.29H83.6299L123.34 278.01L83.6201 317.72H95.0898L134.8 278.01L95.0898 238.29Z"
          fill="white"
        />
        <path
          d="M15.6602 317.72H4.2002L39.71 282.2L75.23 317.72H63.77L39.71 293.67L15.6602 317.72Z"
          fill="white"
        />
        <path
          d="M123.34 317.72H134.8L158.86 293.67L182.91 317.72H194.37L158.86 282.2L123.34 317.72Z"
          fill="white"
        />
        <path
          d="M278 242.49L202.77 317.72H214.23L278 253.95L341.76 317.72H353.23L278 242.49Z"
          fill="white"
        />
        <path
          d="M35.52 317.72H24.0601L39.71 302.06L55.3701 317.72H43.9102L39.71 313.53L35.52 317.72Z"
          fill="white"
        />
        <path
          d="M838.19 317.72H849.65L873.7 293.67L897.76 317.72H909.22L873.7 282.2L838.19 317.72Z"
          fill="white"
        />
        <path
          d="M988.65 317.72H977.18L992.84 302.06L1008.5 317.72H997.04L992.84 313.53L988.65 317.72Z"
          fill="white"
        />
        <path
          d="M957.33 317.72H968.79L992.84 293.67L1016.9 317.72H1028.36L992.84 282.2L957.33 317.72Z"
          fill="white"
        />
        <path
          d="M948.93 317.72H937.47L992.84 262.35L1048.22 317.72H1036.75L992.84 273.81L948.93 317.72Z"
          fill="white"
        />
      </g>
    </svg>
  )
}

export { BannerTexture }
