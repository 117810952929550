import { type UseAvatarGroupProps, useAvatarGroup } from './use-avatar-group'

export type AvatarGroupProps = UseAvatarGroupProps

const AvatarGroup = (props: AvatarGroupProps) => {
  const { styles, className, avatars } = useAvatarGroup(props)

  return <div className={styles.base({ className })}>{avatars}</div>
}

export { AvatarGroup }
