import { tv, type VariantProps } from '@construkto/ui/lib/utils'

const heroVariants = tv({
  slots: {
    base: [
      'grid grid-cols-1 md:grid-cols-2',
      'items-center gap-10 lg:gap-20',
      'py-10 md:py-16',
    ],
    caption: ['col-span-1'],
    brand: ['w-48', 'mx-auto md:mx-0 mb-10', 'text-orange-500'],
    title: [
      'text-gray-700',
      'text-center md:text-left',
      'lg:max-w-lg 2xl:max-w-xl',
    ],
    description: ['mt-6', 'text-center md:text-left'],
    action: ['mt-8'],
    figure: ['col-span-1'],
    image: ['object-contain'],
  },
})

export type HeroVariantProps = VariantProps<typeof heroVariants>

export { heroVariants }
