'use client'

import { Banner } from '@construkto/ui/components/banner'
import { useMarketing } from '~/hooks/use-marketing'

const SectionBanner = () => {
  const { toggleModal } = useMarketing()

  return (
    <Banner
      emoji="🤩"
      title="Estamos construindo uma ferramenta incrível e você é peça chave pra gente."
      action={{
        label: 'Quero ser avisado!',
        onClick: () => toggleModal(true),
      }}
    />
  )
}

export { SectionBanner }
