import NextImage from 'next/image'
import NextLink from 'next/link'
import { Typography } from '@construkto/ui/components/typography'
import { Brand } from '@construkto/ui/components/brand'
import { Button } from '@construkto/ui/components/button'
import { AspectRatio } from '@construkto/ui/components/aspect-ratio'
import { type UseHeroProps, useHero } from './use-hero'

export type HeroProps = UseHeroProps

const Hero = (props: HeroProps) => {
  const { styles, className, showBrand, data } = useHero(props)
  const { title, description, image, action } = data

  return (
    <section className={styles.base({ className })}>
      <div className={styles.caption()}>
        {showBrand && <Brand className={styles.brand()} />}
        <Typography
          as="h1"
          className={styles.title()}
          fontSize="heading-lg"
          fontFamily="jakarta"
          fontWeight="bold"
        >
          {title}
        </Typography>
        <Typography as="p" className={styles.description()} fontSize="body-md">
          {description}
        </Typography>
        {!!action && (
          <>
            {!!action.url && (
              <Button
                className={styles.action()}
                appearance="solid"
                fullWidth="sm"
                asChild
              >
                <NextLink href={action.url} title={action.label}>
                  {action.label}
                </NextLink>
              </Button>
            )}
            {!!action.onClick && (
              <Button
                className={styles.action()}
                appearance="solid"
                fullWidth="sm"
                onClick={action.onClick}
              >
                {action.label}
              </Button>
            )}
          </>
        )}
      </div>
      <div className={styles.figure()}>
        <AspectRatio ratio={1 / 1}>
          <NextImage fill src={image} alt={title} className={styles.image()} />
        </AspectRatio>
      </div>
    </section>
  )
}

export { Hero }
