import {
  type ReactNode,
  type ReactElement,
  Children,
  cloneElement,
  isValidElement,
} from 'react'
import {
  AvatarGroupVariantProps,
  avatarGroupVariants,
} from './avatar-group.variants'
import { Avatar, AvatarProps } from '../avatar'

export type UseAvatarGroupProps = AvatarGroupVariantProps & {
  className?: string
  size?: AvatarProps['size']
  children: ReactNode
}

const useAvatarGroup = (props: UseAvatarGroupProps) => {
  const {
    className = '',
    size = 'md',
    borderColor = 'orange',
    children,
    ...rest
  } = props

  const styles = avatarGroupVariants({ borderColor })

  const avatars = Children.map(children, (child, index) => {
    if (isValidElement(child) && child.type === Avatar) {
      return cloneElement(child as ReactElement<AvatarProps>, {
        key: `avatar-${index}`,
        className: styles.avatar({
          className: child.props?.className,
        }),
        size,
      })
    }

    return null
  })

  return {
    styles,
    className,
    size,
    avatars,
    ...rest,
  }
}

export { useAvatarGroup }
