import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { toast } from 'sonner'
import { useMarketing } from '~/hooks/use-marketing'

const conversionFormSchema = z.object({
  name: z.string().min(1, 'Nome é obrigatório'),
  email: z.string().min(1, 'E-mail é obrigatório').email('E-mail inválido'),
  phone: z.string().min(10, 'Telefone é obrigatório'),
})

const useConversionForm = () => {
  const { showModal, toggleModal } = useMarketing()

  const form = useForm<z.infer<typeof conversionFormSchema>>({
    resolver: zodResolver(conversionFormSchema),
    defaultValues: {
      name: '',
      email: '',
      phone: '',
    },
  })

  const onSubmit = async (values: z.infer<typeof conversionFormSchema>) => {
    try {
      await fetch('/api/conversion', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      })

      toast.success('Enviado com sucesso!')

      toggleModal(false)
      form.reset()
    } catch (error) {
      toast.error('Ocorreu um erro ao enviar o formulário')
    }
  }

  return {
    showModal,
    toggleModal,
    form,
    onSubmit,
  }
}

export { useConversionForm }
