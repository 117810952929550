import NextLink from 'next/link'
import { AvatarGroup } from '../avatar-group'
import { Button } from '../button'
import { Typography } from '../typography'
import { BannerTexture } from './banner.texture'
import { BrandIcon } from '../brand'
import { Avatar } from '../avatar'
import { type UseBannerProps, useBanner } from './use-banner'

export type BannerProps = UseBannerProps

const Banner = (props: BannerProps) => {
  const { styles, className, emoji, title, action } = useBanner(props)

  return (
    <div className={styles.base({ className })}>
      <BannerTexture className={styles.texture()} />
      <div className={styles.content()}>
        <AvatarGroup size="lg" borderColor="dark">
          {!!emoji && (
            <Avatar
              fallback={<span className={styles.emoji()}>{emoji}</span>}
            />
          )}
          <Avatar
            fallback={<BrandIcon className={styles.brand()} />}
            fallbackProps={{
              className: 'bg-orange-500',
            }}
          />
        </AvatarGroup>
        <Typography
          as="h3"
          fontSize="heading-xxs"
          fontWeight="bold"
          fontFamily="jakarta"
          className={styles.title()}
        >
          {title}
        </Typography>
        {!!action && (
          <>
            {!!action.url && (
              <Button
                className={styles.action()}
                fullWidth="sm"
                appearance="solid"
                asChild
              >
                <NextLink href={action.url} title={action.label}>
                  {action.label}
                </NextLink>
              </Button>
            )}
            {!!action.onClick && (
              <Button
                className={styles.action()}
                fullWidth="sm"
                appearance="solid"
                onClick={action.onClick}
              >
                {action.label}
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export { Banner }
